.checkoutProduct {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  min-width: 100px;
  background-color: white;
}
.checkoutProduct__image {
  max-height: 200px;
  object-fit: contain;
  width: 180px;
  margin-bottom: 15px;
}
.checkoutProduct__info {
  padding-left: 20px;
}
.checkoutProduct__title {
  font-weight: 800;
  font-size: 18px;
}
.checkoutProduct__rating {
  display: flex;
}
.checkoutProduct__button {
  border: 1px solid;
  background: #02d7f2;
  margin-top: 10px;
  border-color: #02d7f2 #1a99aa #065862;
  color: #111;
}
