.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.subtotal__gift {
  display: flex;
  align-items: center;
}
.subtotal__gift > input {
  margin-right: 5px;
}
.subtotal > button {
  border: 1px solid;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  background: #02d7f2;
  margin-top: 10px;
  border-color: #02d7f2 #1a99aa #065862;
  color: #111;
}
