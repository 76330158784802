.header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #090815;
  position: sticky;
  top: 0;
  z-index: 100;
}
.header__logo {
  width: 40px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 5px;
}
.header__search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
}
.header__searchInput {
  width: 100%;
  padding: 10px;
  height: 12px;
  border: none;
}
.header__searchIcon {
  padding: 5px;
  height: 22px !important;
  background-color: #02d7f2;
}
.header__navbar {
  display: flex;
  justify-content: space-evenly;
}
.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  border-radius: 24px;
  color: white;
}
.header__optionLineOne {
  font-size: 10px;
}
.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}
.header__optionBasket {
  display: flex;
  align-items: center;
  color: #02d7f2;
}
.header__basketCount {
  margin-left: 10px;
  margin-right: 10px;
}
