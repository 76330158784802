.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  min-width: 100px;
  background-color: white;
  z-index: 1;
}
.product__info {
  height: 100px;
  margin-bottom: 10px;
}
.product__price {
  margin-top: 5px;
}
.product__rating {
  display: flex;
}
.product__image {
  max-height: 200px;
  object-fit: contain;
  width: 100%;
  margin-bottom: 15px;
}

.product > button {
  border: 1px solid;
  background: #02d7f2;
  margin-top: 10px;
  border-color: #02d7f2 #1a99aa #065862;
  color: #111;
}
