.checkout {
  display: flex;
  padding: 20px;
  height: max-content;
}

.checkout__ad {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
}

.checkout__title {
  margin-right: 10px;
}
